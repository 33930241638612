import {computed, reactive} from 'vue'
import {defineStore} from 'pinia'
import axios from "axios";
import {GuestAppApiUrl} from "@/constants";
import {useGuestAuthStore} from "@/stores/guestAuth";
import {useApartmentStore} from "@/stores/apartment";
import {toLocaleString} from "@/utils";
import {DateTime} from "luxon";

export const useBookingStore = defineStore('booking', () => {
    const guestAuth = useGuestAuthStore()
    const apartmentStore = useApartmentStore()

    const booking = reactive({})

    const isCheckedIn = computed(() => booking.checkedIn)
    const checkInDateTime = computed(() => {
        if (!booking.checkInDateTime) {
            return ''
        }

        return DateTime.fromISO(booking.checkInDateTime, {zone:  apartmentStore.apartment.timezone})
    })
    const checkOutDateTime = computed(() => {
        if (!booking.checkOutDateTime) {
            return ''
        }

        return DateTime.fromISO(booking.checkOutDateTime, {zone:  apartmentStore.apartment.timezone})
    })
    const summary = computed(() => {
        return {
            checkIn: toLocaleString(checkInDateTime.value),
            checkOut: toLocaleString(checkOutDateTime.value),
            guests: booking.guests.totalAmount,
        }
    })

    function fetchBooking() {
        return axios.get(
            '/v1/booking',
            {
                baseURL: GuestAppApiUrl,
                headers: {
                    "X-API-Key": guestAuth.token,
                }
            }
        )
            .then(({data}) => Object.assign(booking, data))
            .catch(err => {
                console.error(err)

                throw err
            })
    }

    return {
        booking,
        isCheckedIn,
        checkInDateTime,
        checkOutDateTime,
        summary,
        fetchBooking
    }
})
