<script setup>
import world from '@/misc/world.json'
import {computed} from "vue";
import {isEmpty} from "@/utils";

const props = defineProps(['title', "placeholder", "id", "modelValue", "required", "disabled"])
const emits = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
</script>

<template>
  <label :for="id">
    {{ $t(props.title) }}
    <select
        :id="id"
        :aria-invalid="isEmpty(value) ? undefined : !value"
        v-model="value"

        :required="props.required"
        :disabled="props.disabled"
    >
      <option value="" selected>{{ $t(props.placeholder) }}</option>
      <template v-for="country in world" :key="country.id">
        <option :value="country.alpha2.toUpperCase()">{{ country[$i18n.locale] ?? country[$i18n.fallbackLocale] }}
        </option>
      </template>

    </select>
  </label>
</template>

<style scoped>

</style>