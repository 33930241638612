import './assets/base.css'
import '@picocss/pico/css/pico.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import "vue-toastification/dist/index.css";
import './icons'

import {createApp} from 'vue'
import {createPinia} from 'pinia'


import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueMarkdown from 'vue-markdown-render'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Toast, {POSITION} from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('vue-markdown', VueMarkdown)

app.use(i18n)
app.use(createPinia())
app.use(Toast, {position: POSITION.TOP_RIGHT, timeout: 10000})
app.use(router)

// Logging
if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: "https://9a72410d2509f1311085eb4f44759bff@o4505853442457600.ingest.sentry.io/4505853443571712",
        integrations: [
            new CaptureConsole({levels: ['error']}),
            new Sentry.Replay(),
        ],
        tracesSampleRate: false,
        replaysSessionSampleRate: false,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.mount('#app')
