<script setup>

import {computed, onMounted, ref, watch} from "vue";

const props = defineProps(['title', 'available', 'modelValue'])
const emits = defineEmits(['update:modelValue'])

const checked = ref(false)
const counter = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', checked.value ? value : 0)
  }
})

watch(checked, isChecked => {
  if (isChecked) {
    counter.value = 1

    return
  }

  counter.value = 0
})

onMounted(() => {
  if (counter.value > 0) {
    checked.value = true
  }
})
</script>

<template>
  <div class="grid">
    <div>
      <label :for="title">
        <input
            type="checkbox"
            :id="title"
            :name="title"
            v-model="checked"
        />
        {{ $t(props.title) }}
      </label>
    </div>
    <div class="grid" v-if="checked && props.available > 1">
      <button @click.prevent="counter--" v-if="counter > 1">-</button>
      <input type="number" class="inpt-number" :value="counter" disabled>
      <button @click.prevent="counter++" v-if="counter < props.available">+</button>
    </div>
  </div>
</template>

<style scoped>
.inpt-number {
  text-align: center;
}
</style>