import { ref } from 'vue'
import { defineStore } from 'pinia'
import {useBookingStore} from "@/stores/booking";
import {useApartmentStore} from "@/stores/apartment";

export const useGuestAuthStore = defineStore('guestAuth', () => {
    const token = ref('')
    const isAuthorized = ref(false)
    const booking = useBookingStore()
    const apartment = useApartmentStore()

    async function login(t) {
        token.value = t

        try {
            await booking.fetchBooking()
            await apartment.fetchApartment()

            isAuthorized.value = true
        } catch (error) {
            isAuthorized.value = false
            console.error(error)

            return false
        }

        return true
    }

    return { token, isAuthorized, login }
})
