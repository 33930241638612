<script setup>
import {computed, onMounted, ref} from "vue";
import {isEmpty} from "@/utils";
import CountryDropdown from "@/components/CountryDropdown.vue";
import BirthdayPicker from "@/components/BirthdayPicker.vue";
import {useGuestsStore} from "@/stores/guests";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";

const props = defineProps(['id', 'index'])
const emits = defineEmits(['saved'])

const guestsStore = useGuestsStore()
const i18n = useI18n()
const toast = useToast()

const isSaving = ref(false)
const form = ref(null)
let buffer = {};
const formData = computed({
  get: () => guestsStore.guests[props.index],
  set: value => buffer = value,
})

const isRegistered = computed({
  get: () => !!guestsStore.guests[props.index].hash,
  set: v => guestsStore.guests[props.index].hash = v,
})

onMounted(() => {
  buffer = guestsStore.guests[props.index]
})

function save() {
  if (!form.value.checkValidity()) {
    return;
  }

  isSaving.value = true

  guestsStore
      .saveGuest(buffer, props.index)
      .then(() => emits('saved'))
      .catch(err => {
        console.error(err)
        toast.error(i18n.t('alerts.somethingWentWrong'))
      })
      .finally(() => isSaving.value = false)

}

function validate(value, length = 2) {
  return value.length >= length;
}
</script>

<template>
  <form action="#" @submit.prevent="save" ref="form">
    <div class="grid">
      <label :for="props.id + '-firstname'">
        {{ $t("firstName") }}
        <input
            type="text"
            :id="props.id + '-firstname'"
            :placeholder="$t('firstName')"
            :aria-invalid="isEmpty(formData.firstName) ? undefined : !validate(formData.firstName)"
            v-model="formData.firstName"

            required
            :disabled="isRegistered"
        />
      </label>

      <label :id="props.id + '-lastname'">
        {{ $t("lastName") }}
        <input
            type="text"
            :id="props.id + '-lastname'"
            :placeholder="$t('lastName')"
            :aria-invalid="isEmpty(formData.lastName) ? undefined : !validate(formData.lastName)"
            v-model="formData.lastName"

            required
            :disabled="isRegistered"
        />
      </label>

      <birthday-picker
          :id="props.id + '-birthday'"
          v-model="formData.birthday"
          :required="true"
          :disabled="isRegistered"
      />
    </div>

    <div class="grid">
      <label :id="props.id + '-address'">
        {{ $t("address") }}
        <input
            type="text"
            :id="props.id + '-address'"
            :placeholder="$t('address')"
            :aria-invalid="isEmpty(formData.address) ? undefined : !validate(formData.address)"
            v-model="formData.address"

            required
            :disabled="isRegistered"
        />
      </label>

      <div class="grid">
        <label :for="props.id + '-city'">
          {{ $t("city") }}
          <input
              type="text"
              :id="props.id + '-city'"
              :placeholder="$t('city')"
              :aria-invalid="isEmpty(formData.city) ? undefined : !validate(formData.city)"
              v-model="formData.city"

              required
              :disabled="isRegistered"
          >
        </label>

        <label :for="props.id + '-postal-code'">
          {{ $t("postalCode") }}
          <input
              type="text"
              :id="props.id + '-postal-code'"
              :placeholder="$t('postalCode')"
              :aria-invalid="isEmpty(formData.postalCode) ? undefined : !validate(formData.postalCode)"
              v-model="formData.postalCode"

              required
              :disabled="isRegistered"
          />
        </label>
      </div>
    </div>

    <country-dropdown
        title="country"
        placeholder="select"
        :id="props.id + '-country'"
        v-model="formData.country"

        :required="true"
        :disabled="isRegistered"
    />

    <div class="grid">
      <label :for="props.id + '-document-type'">{{ $t("documentType") }}
        <select
            :id="props.id + '-document-type'"
            :aria-invalid="isEmpty(formData.documentType) ? undefined : !validate(formData.documentType)"
            v-model="formData.documentType"

            required
            :disabled="isRegistered"
        >
          <option value="" selected>{{ $t("select") }}</option>
          <option value="national_id">{{ $t("nationalId") }}</option>
          <option value="passport">{{ $t("passport") }}</option>
          <option value="driving_license">{{ $t("drivingLicense") }}</option>
        </select>
      </label>

      <label :for="props.id + '-document-number'">
        {{ $t("documentNumber") }}
        <input
            type="text"
            :id="props.id + '-document-number'"
            :placeholder="$t('documentNumber')"
            :aria-invalid="isEmpty(formData.documentNumber) ? undefined : !validate(formData.documentNumber)"
            v-model="formData.documentNumber"

            required
            :disabled="isRegistered"
        />
      </label>

      <country-dropdown
          title="nationality"
          placeholder="select"
          :id="props.id + '-nationality'"
          v-model="formData.nationality"

          :required="true"
          :disabled="isRegistered"
      />

      <label :for="props.id + '-purpose-of-stay'">{{ $t("purposeOfStay") }}
        <select
            :id="props.id + '-prupose-of-stay'"
            :aria-invalid="isEmpty(formData.purposeOfStay) ? undefined : !validate(formData.purposeOfStay)"
            v-model="formData.purposeOfStay"

            required
            :disabled="isRegistered"
        >
          <option value="" selected>{{ $t("select") }}</option>
          <option value="tourism">{{ $t("tourism") }}</option>
          <option value="business">{{ $t("businessTrip") }}</option>
        </select>
      </label>
    </div>

    <template v-if="!isRegistered">
      <button type="submit" class="warning" v-if="!isSaving">{{ $t("save") }}</button>
      <button aria-busy="true" class="secondary" disabled v-else></button>
    </template>
    <button class="secondary" @click.prevent="isRegistered = false"  v-else>{{ $t("change") }}</button>
  </form>
</template>