<script setup>

import {useBookingStore} from "@/stores/booking";
import {useApartmentStore} from "@/stores/apartment";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps(['title'])

const bookingStore = useBookingStore()
const apartmentStore = useApartmentStore()
</script>

<template>
  <hgroup>
    <h2 v-if="!props.title">Apartment {{ apartmentStore.apartment.name }}</h2>
    <h2 v-else>{{ props.title }}</h2>
    <h3>
      <font-awesome-icon icon="fa-solid fa-plane-arrival" class="px-1" /> {{ bookingStore.summary.checkIn }} <i>|</i>
      <font-awesome-icon icon="fa-solid fa-plane-departure" class="px-1"/> {{ bookingStore.summary.checkOut }} <i>|</i>
      {{ bookingStore.summary.guests }} {{ $t('guests', bookingStore.summary.guests) }}
    </h3>
  </hgroup>
</template>

<style scoped>

</style>