<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import {GuestAppApiUrl, HttpStatusLocked} from "@/constants";
import {useGuestAuthStore} from "@/stores/guestAuth";
import {useToast} from "vue-toastification";
import {useBookingStore} from "@/stores/booking";
import {useI18n} from "vue-i18n";
import {useApartmentStore} from "@/stores/apartment";
import {computed, ref} from "vue";
import {DateTime} from "luxon";
import Modal from "@/components/Modal.vue";
import BookingInfoTitle from "@/components/BookingInfoTitle.vue";

const guestAuth = useGuestAuthStore()
const bookingStore = useBookingStore()
const apartmentStore = useApartmentStore()
const i18n = useI18n()
const toast = useToast()

const isLoading = ref({front_door: false, apartment_door: false})
const isReqLocked = ref({front_door: false, apartment_door: false})
const apartmentDoorConfirmModal = ref(false)

const doorTypes = {
  frontDoor: 'front_door',
  apartmentDoor: 'apartment_door',
}
const doorActions = {
  unlock: 'unlock',
  lock: 'lock',
}
const waitTimeout = 3000 // 3s
const reqLockTimeOut = 20000 // 20s

const isAvailable = computed(() => {
  return DateTime.now() > bookingStore.checkInDateTime
})

function btnDoorLockedTimeout(type) {
  let countdown = reqLockTimeOut / 1000
  isReqLocked.value[type] = true

  const tid = toast.warning(i18n.t('alerts.doorRequestLocked', {count: countdown}), {timeout: reqLockTimeOut})

  const ival = setInterval(() => {
    countdown--
    toast.update(tid, {content: i18n.t('alerts.doorRequestLocked', {count: countdown})})
  }, 1000)

  setTimeout(() => {
    isReqLocked.value[type] = false
    toast.dismiss(tid)
    clearInterval(ival)
  }, reqLockTimeOut)
}

function doorAction(action, type) {
  if (isLoading.value[type]) {
    return;
  }

  isLoading.value[type] = true;

  axios.post(
      '/v1/door/action',
      {action, type},
      {
        baseURL: GuestAppApiUrl,
        headers: {
          "X-API-Key": guestAuth.token,
        }
      }
  )
      .then(() => {
        let msg = action === doorActions.unlock ? i18n.t('doorOpened') : i18n.t('doorClosed')

        setTimeout(() => {
          toast.success(msg)
          isLoading.value[type] = false
        }, waitTimeout)
      })
      .catch(err => {
        if (err.response && err.response.status === HttpStatusLocked) {
          btnDoorLockedTimeout(type)
        } else {
          console.error(err)
          toast.error(i18n.t('alerts.somethingWentWrong'))
        }

        isLoading.value[type] = false
      })
}

function openApartmentDoor() {
  apartmentDoorConfirmModal.value = false
  doorAction(doorActions.unlock, doorTypes.apartmentDoor)
}
</script>

<template>
  <booking-info-title :title="$t('doorKeys')" />
  <div class="row">
    <div class="col-12 col-lg-6" v-if="apartmentStore.apartment.smartLocks?.frontDoor">
      <article class="door-card">
        <h2 class="door-card__title">
          <font-awesome-icon icon="fa-solid fa-building"/>
          {{ $t('frontDoor') }}
        </h2>
        <footer>
          <button
              @click="doorAction(doorActions.unlock, doorTypes.frontDoor)"

              :aria-busy="isLoading[doorTypes.frontDoor]"
              :disabled="!isAvailable || isLoading[doorTypes.frontDoor] || isReqLocked[doorTypes.frontDoor]"
          >
          <span v-if="!isLoading[doorTypes.frontDoor]">
            <font-awesome-icon icon="fa-solid fa-lock-open"/> {{ $t('open') }}
          </span>
          </button>
        </footer>
      </article>
    </div>
    <div class="col-12 col-lg-6">
      <article class="door-card">
        <hgroup class="door-card__title">
          <h2>
            <font-awesome-icon icon="fa-solid fa-door-closed"/>
            {{ $t('apartmentDoor') }}
          </h2>
          <h3>
            {{ apartmentStore.apartment.location.floor }}. {{ $t('floor') }}, {{ $t(apartmentStore.apartment.location.side) }}
          </h3>
        </hgroup>
        <footer>
          <div class="row">
            <div class="col-6">
              <button
                  @click="apartmentDoorConfirmModal = true"

                  :aria-busy="isLoading[doorTypes.apartmentDoor]"
                  :disabled="!isAvailable || isLoading[doorTypes.apartmentDoor] || isReqLocked[doorTypes.apartmentDoor]"
              >
              <span v-if="!isLoading[doorTypes.apartmentDoor]">
                <font-awesome-icon icon="fa-solid fa-lock-open"/> {{ $t('open') }}
              </span>
              </button>
            </div>
            <div class="col-6">
              <button
                  class="contrast"

                  @click="doorAction(doorActions.lock, doorTypes.apartmentDoor)"

                  :aria-busy="isLoading[doorTypes.apartmentDoor]"
                  :disabled="!isAvailable || isLoading[doorTypes.apartmentDoor] || isReqLocked[doorTypes.apartmentDoor]"
              >
              <span v-if="!isLoading[doorTypes.apartmentDoor]">
                <font-awesome-icon icon="fa-solid fa-lock"/> {{ $t('close') }}
              </span>
              </button>
            </div>
          </div>
        </footer>
      </article>
    </div>
  </div>

  <modal v-model:show="apartmentDoorConfirmModal" :title="$t('apartmentDoorConfirmModal.title')">
    <template #content>
      <p v-html="$t('apartmentDoorConfirmModal.content')"></p>
    </template>
    <template #footer>
      <a href="#" role="button" @click.prevent="openApartmentDoor()">
        <font-awesome-icon icon="fa-solid fa-lock-open"/> {{ $t('open') }}
      </a>
    </template>
  </modal>
</template>

<style>
.door-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 12.75rem;
  box-shadow: none;
  text-align: center;
  border: 1px solid #24333e;
  border-radius: 5px;
  margin: 10px;
}

.door-card__title {
  font-size: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 0;
}

.door-card__title svg {
  padding-right: 0.56rem;
}

.door-card header {
  font-weight: 700;
  font-size: 1.75rem;
}

.door-card footer {
  margin-top: 0;
  background-color: #e6e6e6;
}

.door-card button {
  margin: 0;
}
</style>