<script setup>
import BookingInfo from "@/components/BookingInfoTitle.vue";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import {useBookingStore} from "@/stores/booking";
import {computed} from "vue";
import TileButtonGroup from "@/components/TileButtonGroup.vue";
import {useRouter} from "vue-router";
import {WirtzWorldPhone, WirtzWorldUrl} from "@/constants";

const bookingStore = useBookingStore()
const router = useRouter()
const i18n = useI18n()
const toast = useToast()

const isCheckedIn = computed(() => bookingStore.isCheckedIn)

function checkInAlertMessage() {
  toast.info(i18n.t('checkinAlertMessage'))
}

const tiles = computed(() => [
  {
    title: i18n.t('checkin'),
    icon: 'fa-solid fa-user-check',
    cb: () => {
      if (bookingStore.isCheckedIn) {
        return toast.success(i18n.t('alerts.youAlreadyCheckedIn'))
      }

      router.push({name: 'booking.checkin'})
    },
  },
  {
    title: i18n.t('key', 2),
    icon: 'fa-solid fa-key',
    cb: () => {
      if (!isCheckedIn.value) {
        checkInAlertMessage()

        return
      }

      router.push({name: 'booking.doorkeys'})
    }
  },
  {
    title: i18n.t('guestfolder'),
    icon: 'fa-solid fa-book-open',
    cb: () => router.push({name: 'booking.guestfolder'}),
  },
  {
    title: i18n.t('website'),
    icon: 'fa-solid fa-globe',
    cb: () => window.open(WirtzWorldUrl),
  },
  {
    title: 'WhatsApp',
    icon: 'fa-brands fa-whatsapp',
    cb: () => window.open('https://wa.me/' + WirtzWorldPhone),
  },
  {
    title: i18n.t('phone'),
    icon: 'fa-solid fa-phone',
    cb: () => window.open('tel:' + WirtzWorldPhone),
  },
])
</script>

<template>
  <div>
    <booking-info/>
    <tile-button-group
        :tiles="tiles"
        col="col-6 col-md-4"
    />
  </div>
</template>