const toLocaleString = dateTime => {
    return dateTime.toFormat('dd.MM.yy, t')
}

const isEmpty = value => {
    return !value
}

export {
    isEmpty,
    toLocaleString,
}