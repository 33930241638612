<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AddonCheckbox from "@/components/AddonCheckbox.vue";
import {computed, ref} from "vue";
import {useApartmentStore} from "@/stores/apartment";
import {useBookingStore} from "@/stores/booking";
import {useGuestsStore} from "@/stores/guests";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import {useRouter} from "vue-router";
import Modal from "@/components/Modal.vue";

const apartmentStore = useApartmentStore()
const bookingStore = useBookingStore()
const guestsStore = useGuestsStore()
const i18n = useI18n()
const toast = useToast()
const router = useRouter()

const isCheckingIn = ref(false)
const bmgHint = ref(false)

const hasApartmentAddons = computed(() => {
  const addons = apartmentStore.apartment.availableAddons
  for (const key in addons) {
    if (addons[key] > 0) {
      return true
    }
  }

  return false
})

const canCheckIn = computed(() => {
  return guestsStore.guestsValidAmount === bookingStore.booking.guests.totalAmount
})

function checkIn() {
  if (!canCheckIn.value) {
    return
  }

  isCheckingIn.value = true

  guestsStore.checkIn()
      .then(() => {
        toast.success(i18n.t('alerts.successfullyCheckedIn'));
        router.push({name: 'booking.home'})
      })
      .catch(err => {
        console.error(err)
        toast.error(i18n.t('alerts.somethingWentWrong'));
      })
      .finally(() => isCheckingIn.value = false)
}
</script>

<template>
  <form action="#" @submit.prevent="checkIn">
    <template v-if="hasApartmentAddons">
      <fieldset>
        <h3 class="addons-header">{{ $t('addons') }}</h3>
        <template v-for="(value, addonName) in apartmentStore.apartment.availableAddons" :key="addonName">
          <addon-checkbox
              :title="'addonTypes.' + addonName"
              :available="value"
              v-model="bookingStore.booking.addons[addonName]"
              v-if="value > 0"
          />
        </template>
      </fieldset>
      <hr/>
    </template>
    <label for="signature" class="signature-checkbox">
      <input type="checkbox" id="signature" name="signature" required>
      <span v-html="$t('signatureAgreement')"></span>
      {{ $t('moreInfo') }} <a href="#" @click="bmgHint = true">{{ $t('here') }}</a>
    </label>

    <button type="submit" class="contrast" :aria-busy="isCheckingIn" :disabled="!canCheckIn">
      <font-awesome-icon icon="fa-solid fa-user-check"/>
      {{ $t('checkinNow') }}
    </button>
  </form>

  <modal v-model:show="bmgHint" :title="$t('bmgModal.title')">
    <template #content>
      <p v-html="$t('bmgModal.content')"></p>
    </template>
  </modal>
</template>

<style scoped>
.signature-checkbox {
  padding: 20px 20px 20px 0;
}

.addons-header {
  margin-bottom: 5px;
}
</style>