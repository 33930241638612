import {computed, ref} from 'vue'
import {defineStore} from 'pinia'
import axios from "axios";
import {GuestAppApiUrl} from "@/constants";
import {useGuestAuthStore} from "@/stores/guestAuth";
import {useBookingStore} from "@/stores/booking";
import {DateTime} from "luxon";

const guestDefaultObj = {
    position: '',
    firstName: '',
    lastName: '',
    birthday: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    documentType: '',
    documentNumber: '',
    nationality: '',
    purposeOfStay: '',
    hash: ''
}

const initGuests = amount => {
    const buffer = [];

    for (let i = 0; i < amount; i++) {
        buffer[i] = {
            position: i,
            ...guestDefaultObj,
        }
    }

    return buffer
};

export const useGuestsStore = defineStore('guests', () => {
    const bookingStore = useBookingStore()
    const guestAuth = useGuestAuthStore()
    const guests = ref(initGuests(bookingStore.booking.guests.totalAmount))
    const addons = ref({})

    const guestsValidAmount = computed(
        () => guests.value.filter(guest => guest.hash).length
    )

    function fetchGuests() {
        return axios.get(
            '/v1/booking/guests',
            {
                baseURL: GuestAppApiUrl,
                headers: {
                    "X-API-Key": guestAuth.token,
                }
            }
        )
            .then(({data}) => {
                if (data.length === 0) {
                    return
                }

                guests.value = guests.value
                    .map(
                        (guest, index) => {
                            const savedGuest = data.find(sg => sg.position === index)
                            if (savedGuest) {
                                savedGuest.birthday = DateTime.fromISO(savedGuest.birthday).toFormat('yyyy-MM-dd')
                                return savedGuest
                            }

                            return guest
                        }
                    );
            })
    }

    function saveGuest(guest, index) {
        return axios.post(
            '/v1/booking/guests',
            {
                index: index,
                ...guest,
            },
            {
                baseURL: GuestAppApiUrl,
                headers: {
                    "X-API-Key": guestAuth.token,
                }
            }
        )
            .then(({data}) => guests.value[index].hash = data.hash)
    }

    function checkIn() {
        return axios.post(
            '/v1/booking/checkin',
            {addons: bookingStore.booking.addons},
            {
                baseURL: GuestAppApiUrl,
                headers: {
                    "X-API-Key": guestAuth.token,
                }
            }
        )
            .then(() => bookingStore.booking.checkedIn = true)
    }

    return {guests, guestsValidAmount, addons, fetchGuests, saveGuest, checkIn}
})
