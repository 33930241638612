<script setup>

import TileButton from "@/components/TileButton.vue";

const props = defineProps(['tiles', 'col'])

function invoker(cb) {
  if (!cb) {
    return
  }

  cb()
}
</script>

<template>
  <div class="container p-0">
    <div class="row g-4">
      <template v-for="tile in props.tiles" :key="'tile-' + tile.title">
        <div :class="tile.col ?? col">
          <tile-button
              :title="tile.title"
              :icon="tile.icon"

              @click="invoker(tile.cb)"
          />
        </div>
      </template>
    </div>
  </div>
</template>
