<script setup>
</script>

<template>
  <div class="container">
    <div class="login-card">
      <article class="col-md-6">
        <h1 class="mb-3">Login</h1>
        <form>
          <input
              type="text"
              name="username"
              placeholder="Username"
              autocomplete="username"
              required
          />
          <input
              type="password"
              name="password"
              placeholder="Password"
              autocomplete="current-password"
              required
          />
          <fieldset>
            <label for="remember">
              <input type="checkbox" role="switch" id="remember" name="remember"/>
              Remember me
            </label>
          </fieldset>
          <button type="submit" class="contrast" onclick="event.preventDefault()">Login</button>
        </form>
      </article>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.login-card {
  display: flex;
  justify-content: center;
}
</style>