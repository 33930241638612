import {reactive} from 'vue'
import { defineStore } from 'pinia'
import axios from "axios";
import {GuestAppApiUrl} from "@/constants";
import {useGuestAuthStore} from "@/stores/guestAuth";

export const useApartmentStore = defineStore('apartment', () => {
    const apartment = reactive({})
    const guestAuth = useGuestAuthStore()

    function fetchApartment() {
        return axios.get(
            '/v1/apartment',
            {
                baseURL: GuestAppApiUrl,
                headers: {
                    "X-API-Key": guestAuth.token,
                }
            }
        )
            .then(({data}) => Object.assign(apartment, data))
            .catch(err => {
                console.error(err)

                throw err
            })
    }

    return { apartment, fetchApartment }
})
