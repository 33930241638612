import {createI18n} from 'vue-i18n';
import {EN, DE, locales} from './locales'
import messagesDE from './messages.de.json'
import messagesEN from './messages.en.json'

const urlParams = new URLSearchParams(window.location.search);

const getRequestedLocale = () => {
    let lc = urlParams.get('locale')

    if (locales.includes(lc)) {
        return lc
    }

    return EN;
}

const i18n = createI18n({
    locale: getRequestedLocale(),
    fallbackLocale: EN,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackWarn: false,
    missingWarn: false,
    warnHtmlMessage: false,
    globalInjection: true,
    legacy: false,
    messages: {
        [DE]: messagesDE,
        [EN]: messagesEN,
    }
})

export default i18n