import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/booking/HomeView.vue'
import GuestFolderView from '@/views/booking/GuestFolderView.vue'
import CheckinView from "@/views/booking/CheckinView.vue";
import DoorKeysView from "@/views/booking/DoorKeysView.vue";
import BookingView from "@/views/booking/BookingView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import AdminView from "@/views/admin/AdminView.vue";
import DashboardView from "@/views/admin/DashboardView.vue";
import LoginView from "@/views/admin/LoginView.vue";
import {adminGuard, bookingGuard} from "@/router/guards";
import {useBookingStore} from "@/stores/booking";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/admin/login',
      name: 'admin.login',
      component: LoginView,
    },
    {
      path: '/admin',
      component: AdminView,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'admin.dashboard',
          component: DashboardView,
        }
      ]
    },
    {
      path: '/booking/:token',
      component: BookingView,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'booking.home',
          component: HomeView
        },
        {
          path: 'checkin',
          name: 'booking.checkin',
          component: CheckinView,
          beforeEnter: (to) => {
            const bookingStore = useBookingStore()
            if (bookingStore.isCheckedIn) {
              return {...to, name: "booking.home"}
            }
          }
        },
        {
          path: 'doorkeys',
          name: 'booking.doorkeys',
          component: DoorKeysView
        },
        {
          path: 'guestfolder',
          name: 'booking.guestfolder',
          component: GuestFolderView
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not_found',
      component: NotFoundView
    }
  ]
})

router.beforeEach(async (to, from) => {
  if (to.meta.requiresAuth) {
    if (to.name.startsWith('booking.')) {
      return await bookingGuard(to, from)
    }

    if (to.name.startsWith('admin.')) {
      return adminGuard(to, from)
    }
  }
});

export default router
