const GuestAppApiUrl = import.meta.env.VITE_GUEST_APP_API_URL
const WirtzWorldUrl = import.meta.env.VITE_WIRTZ_WORLD_URL
const WirtzWorldPhone = import.meta.env.VITE_WIRTZ_WORLD_PHONE

const HttpStatusLocked = 423

export {
    GuestAppApiUrl,
    WirtzWorldUrl,
    WirtzWorldPhone,
    HttpStatusLocked,
}