<script setup>
import CheckinGuestForm from "@/components/CheckinGuestForm.vue";
import {computed, onMounted, ref} from "vue";
import {useBookingStore} from "@/stores/booking";
import {useGuestsStore} from "@/stores/guests";
import CheckinForm from "@/components/CheckinForm.vue";

const bookingStore = useBookingStore()
const guestsStore = useGuestsStore()

const isLoading = ref(false)
const panels = ref([])

const guestsAmount = computed(() => bookingStore.booking.guests.totalAmount)

function closePanel(index) {
  const panel = panels.value[index]
  if (!panel) {
    return
  }

  if (!guestsStore.guests[index].hash) {
    return
  }

  panel.removeAttribute("open");
}

function isValidGuest(guest) {
  return guest.hash
}

onMounted(() => {
  isLoading.value = true

  guestsStore
      .fetchGuests()
      .catch(err => console.error(err))
      .finally(() => isLoading.value = false)
})
</script>

<template>
  <div class="check-in">
    <hgroup>
      <h2>{{ $t('checkin') }}</h2>
      <h3>{{ guestsStore.guestsValidAmount }}/{{ guestsAmount }} {{ $t('guests', guestsAmount) }}</h3>
    </hgroup>

    <div class="grid" v-if="isLoading">
      <a href="#" aria-busy="true">{{ $t('loadingGuests') }}</a>
    </div>
    <template v-else>
      <template v-for="(guest, index) in guestsStore.guests" :key="'guest-' + index">
        <details :ref="el => panels[index] = el">
          <summary role="button" :class="{success: isValidGuest(guest)}">
            {{ $t('guests') }} {{ index + 1 }} {{
              isValidGuest(guest) ? "- " + guest.firstName + " " + guest.lastName : ''
            }}
          </summary>
          <checkin-guest-form
              :index="index"
              :id="'guest-' + index"
              @saved="closePanel(index)"
          />
        </details>
      </template>

      <checkin-form />
    </template>
  </div>
</template>
