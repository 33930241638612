<script setup>
import {isEmpty} from '@/utils'
import {computed} from "vue";
import {DateTime} from "luxon";

const props = defineProps(['modelValue', "id", "required", "disabled"])
const emits = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const birthdayFormat = 'yyyy-MM-dd'
const birthdayLimit = {
  min: DateTime.fromObject({year: 1920, month: 1, day: 1}),
  max: DateTime.now().minus({months: 1})
}

function validate(value) {
  const vDate = DateTime.fromISO(value)
  if (!vDate.isValid) {
    return false
  }

  return vDate >= birthdayLimit.min && vDate <= birthdayLimit.max;
}
</script>

<template>
  <label :for="id">
    {{ $t("birthday") }}
    <input
        type="date"
        :id="id"
        :aria-invalid="isEmpty(value) ? undefined : !validate(value)"
        :min="birthdayLimit.min.toFormat(birthdayFormat)"
        :max="birthdayLimit.max.toFormat(birthdayFormat)"
        v-model="value"

        :required="props.required"
        :disabled="props.disabled"
    />
  </label>
</template>

<style scoped>

</style>