<script setup>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {ref} from "vue";

const props = defineProps(['title', 'icon'])
const emits = defineEmits(['click'])

const active = ref(false)

function clicked(event) {
  active.value = true

  emits('click', event)

  setTimeout(() => active.value = false, 350)
}
</script>

<template>
  <div class="tile-button" :class="{active}" @click="clicked">
    <div class="tile-card__icon">
      <font-awesome-icon :icon="props.icon" />
    </div>
    <div class="tile-card__title">
      {{ props.title }}
    </div>
  </div>
</template>

<style scoped>
.tile-button {
  text-align: center;
  color: #1095c1;
  border: 1px solid #1095c1;
  border-radius: 5px;
  cursor: pointer;
}

.tile-card__title {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile-card__icon {
  font-size: 2.1rem;
  padding: 10px 0 0;
}

.active, .tile-button:hover {
  transition: background-color 0.3s ease;
  border-color: #ffffff;
  background: #1095c1;
  color: #ffffff;;
  text-align: center;
}
</style>