import {useGuestAuthStore} from "@/stores/guestAuth";

const bookingGuard = async (to) => {
    const guestAuth = useGuestAuthStore()

    if (!guestAuth.isAuthorized) {
        const success = await guestAuth.login(to.params.token)

        if (!success) {
            return {name: "not_found"}
        }
    }
};

const adminGuard = (to) => {
    let isAuthenticated = false;

    if (to.name !== 'admin.login' && !isAuthenticated) {
        return {name: 'admin.login'}
    }
};

export {
    bookingGuard,
    adminGuard,
}