<script setup>
</script>

<template>
  <main class="container">
    <hgroup class="content">
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <h5>The Page you are looking for doesn't exist or an other error occurred.</h5>
    </hgroup>
  </main>
</template>

<style lang="css">
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
