import {library} from '@fortawesome/fontawesome-svg-core'

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'

import {
    faAddressBook,
    faBookOpen,
    faBuilding,
    faCalendarCheck,
    faCalendarXmark,
    faDoorClosed,
    faDoorOpen,
    faGlobe,
    faHome,
    faHospital,
    faHouse,
    faKey,
    faLanguage,
    faLocationDot,
    faLock,
    faLockOpen,
    faMap,
    faPhone,
    faScaleBalanced,
    faTrashCan,
    faUserCheck,
    faWifi,
    faArrowRight,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faUserCheck,
    faKey,
    faBookOpen,
    faGlobe,
    faPhone,
    faWhatsapp,
    faHouse,
    faCalendarCheck,
    faCalendarXmark,
    faWifi,
    faTrashCan,
    faHospital,
    faMap,
    faAddressBook,
    faLocationDot,
    faScaleBalanced,
    faDoorOpen,
    faDoorClosed,
    faLanguage,
    faHome,
    faBuilding,
    faLock,
    faLockOpen,
    faArrowRight,
    faPlaneArrival,
    faPlaneDeparture,
)