<script setup>
import Panel from '@/components/Panel.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useApartmentStore} from "@/stores/apartment";
import {computed} from "vue";

const panels = [
  {
    type: "accommodation",
    icon: "fa-solid fa-house",
    content: null
  },
  {
    type: "arrival",
    icon: "fa-solid fa-location-dot",
    content: null
  },
  {
    type: "checkin",
    icon: "fa-solid fa-calendar-check",
    content: null
  },
  {
    type: "checkout",
    icon: "fa-solid fa-calendar-xmark",
    content: null
  },
  {
    type: "internet_and_tv",
    icon: "fa-solid fa-wifi",
    content: null
  },
  {
    type: "trash",
    icon: "fa-solid fa-trash-can",
    content: null
  },
  {
    type: "activities",
    icon: "fa-solid fa-map",
    content: null
  },
  {
    type: "emergency",
    icon: "fa-solid fa-hospital",
    content: null
  },
  {
    type: "house_rules",
    icon: "fa-solid fa-scale-balanced",
    content: null
  },
]
const apartmentStore = useApartmentStore()

const guides = computed(() => {
  return panels
      .map(row => {
        const {content: guideContent} = apartmentStore.apartment.guides[row.type] ?? {}
        row.content = guideContent ?? null
        return row
      })
      .filter(row => row.content !== null);
})
</script>

<template>
  <hgroup>
    <h2>{{ $t('guestfolder') }}</h2>
    <h3>{{ $t('guestfolderSubtitle') }}</h3>
  </hgroup>
  <template v-for="guide in guides" :key="guide.type">
    <Panel>
      <template #title>
        <font-awesome-icon :icon="guide.icon"/>
        {{ $t('guestFolderType.' + guide.type) }}
      </template>
      <template #content>
        <vue-markdown :source="guide.content[$i18n.locale] ?? guide.content[$i18n.fallbackLocale] ?? ''"
                      :options="{html: true}"
        />
      </template>
    </Panel>
  </template>
</template>
