<script setup>
import {computed} from "vue";

const props = defineProps(['show', 'title'])
const emit = defineEmits(['update:show'])

const showValue = computed({
  get() {
    return props.show
  },
  set(value) {
    emit('update:show', value)
  }
})
</script>

<template>
  <dialog :open="showValue">
    <article>
      <header>
        <a href="#close" aria-label="Close" class="close" @click="showValue = false"></a>
        {{ props.title }}
      </header>
      <slot name="content"></slot>
      <footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </footer>
    </article>
  </dialog>
</template>

<style scoped>

</style>