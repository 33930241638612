<script setup>
</script>

<template>
  <div class="container">
    <nav>
      <ul>
        <li>
          <img
              src="@/assets/logo.svg"
              alt="Wirtz World"
              class="logo"
              width="100"
              @click="$router.push({name: 'admin.dashboard'})"
          />
        </li>
      </ul>
      <ul>
        <li><a href="#">Link</a></li>
        <li><a href="#">Link</a></li>
        <li><a href="#" role="button">Button</a></li>
      </ul>
    </nav>
    <div>
      <router-view/>
    </div>
  </div>
</template>
